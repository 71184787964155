import React from 'react'
import { at } from 'lodash'
import { useField } from 'formik'
import { InputLabel, FormControl, Select, MenuItem, FormHelperText, Theme } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { RegionValueObject } from 'src/types/RegionValueObject'

type Props = {
  name: string
  // schema: FieldSchema
  label?: string
  data: RegionValueObject[]
  fullWidth: boolean
}

export default function SelectField({ name, label, data, ...rest }: Props) {
  const [field, meta] = useField(name)
  const classes = useStyles()
  const { value: selectedValue } = field
  const [touched, error] = at(meta, 'touched', 'error')
  const isError = touched && error && true
  function _renderHelperText() {
    if (isError) {
      return <FormHelperText>{error}</FormHelperText>
    }
  }

  return (
    <FormControl {...rest} error={isError} margin={'dense'}>
      <InputLabel shrink htmlFor={name}>
        {label}
      </InputLabel>
      <Select {...field} value={selectedValue ? selectedValue : ''} className={classes.select}>
        {data.map((item: any, index: any) => (
          <MenuItem key={index} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {_renderHelperText()}
    </FormControl>
  )
}

const useStyles = makeStyles(({}: Theme) =>
  createStyles({
    select: {
      'label + &': {
        marginTop: 0
      }
    }
  })
)
