import { Merchant } from 'src/types/Merchant'
import * as Yup from 'yup'

export const merchantFormModel: any = {
  formId: 'merchantForm',
  fields: {
    name: {
      // name: 'name',
      label: 'Nom de mon commerce *',
      initialValue: '',
      validation: Yup.string().required('Ce champ est requis')
    },
    subsidiaries: {
      // name: 'subsidiaries',
      label: 'Succursales',
      initialValue: [],
      validation: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required('Ce champ est requis'),
          region: Yup.string().required('Ce champ est requis')
        })
      )
    }
  }
}

// We remove some properties from the Merchant model
export interface MerchantFormModel extends Omit<Merchant, 'id'> {}

// Initial values and validation schema
export const merchantInitialValues = getConfiguration('initialValue') as MerchantFormModel
export const merchantValidationSchema = Yup.object(getConfiguration('validation'))

function getConfiguration(key: string) {
  const validationRules: { [key: string]: any } = {}
  for (const prop in merchantFormModel.fields) {
    if (merchantFormModel.fields.hasOwnProperty(prop) && merchantFormModel.fields[prop][key]) {
      validationRules[prop] = merchantFormModel.fields[prop][key]
    }
  }
  return validationRules
}
