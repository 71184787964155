import React, { useContext, useState } from 'react'
import { Form, Formik } from 'formik'
import { Button, CircularProgress, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import commonStyles from 'src/styles/commonStyles'
import { InputField } from 'src/components/Molecules/FormFields'
import {
  merchantFormModel,
  merchantValidationSchema,
  MerchantFormModel
} from 'src/components/Views/Merchant/Form/model'
import { FirebaseContext } from 'src/utils/firebase'
import FlashMessageQueue, { FlashMessage } from 'src/components/Molecules/FlashMessageQueue'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import SubsidiaryArrayField from 'src/components/Views/Merchant/Form/SubsidiaryArrayField'

// Export from index
export { merchantFormModel, merchantInitialValues, merchantValidationSchema } from './model'

type Props = {
  initialValues: MerchantFormModel
  id?: string
}

export default function MerchantForm({ initialValues, id }: Props) {
  const { firebase } = useContext(FirebaseContext)
  const { formId, fields } = merchantFormModel
  const [t] = useTranslation()
  const commonClasses = commonStyles()
  const [flashMessages, setFlashMessages] = useState<FlashMessage[]>([])
  const classes = useStyles()

  async function handleSubmit(values: any, {}: any) {
    try {
      values.updatedAt = firebase.firestore.Timestamp.now()
      await firebase
        .firestore()
        .collection('merchants')
        .doc(id)
        .set(values, { merge: true })
        .then(() => {
          const message: FlashMessage = {
            message: t('message.success')
          }
          setFlashMessages([message])
          firebase.analytics().logEvent('merchant_update', values)
        })
    } catch (e) {
      return Promise.reject(e)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={merchantValidationSchema}
      onSubmit={handleSubmit}>
      {({ isSubmitting }) => (
        <Form id={formId}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <InputField name="name" schema={fields.name} fullWidth />
            </Grid>
          </Grid>
          <SubsidiaryArrayField name="subsidiaries" schema={fields.subsidiaries} />

          <Grid container spacing={4}>
            <Grid item xs={12} className={classes.right}>
              <Button
                disabled={isSubmitting}
                type="submit"
                variant="contained"
                color="primary"
                className={'classes.button'}>
                {t('action.update')}
              </Button>
              {isSubmitting && (
                <CircularProgress size={24} className={commonClasses.buttonProgress} />
              )}
            </Grid>
          </Grid>
          {flashMessages.length > 0 && <FlashMessageQueue messages={flashMessages} />}
        </Form>
      )}
    </Formik>
  )
}

const useStyles = makeStyles(({}: Theme) =>
  createStyles({
    right: {
      display: 'flex',
      justifyContent: 'flex-end'
    }
  })
)
