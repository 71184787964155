import React, { ReactElement } from 'react'
import TopBar from 'src/components/Molecules/TopBar'
import { Container } from '@material-ui/core'
import AppFooter from 'src/components/Molecules/Footer'
import commonStyles from 'src/styles/commonStyles'
import Layout from 'src/components/Layout'
import PrivateRoute from 'src/components/PrivateRoute'
import EditForm from 'src/components/Views/Merchant/EditForm'
import Page from 'src/components/Page'

export default function MerchantPage(): ReactElement {
  const commonClasses = commonStyles()

  return (
    <Layout>
      <Page>
        <PrivateRoute path="/login">
          <TopBar />
          <Container component="main" className={commonClasses.mainContentContainer}>
            <EditForm />
          </Container>
          <AppFooter />
        </PrivateRoute>
      </Page>
    </Layout>
  )
}
