import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import { Subsidiary } from 'src/types/Merchant'
import { InputField } from 'src/components/Molecules/FormFields'
import React, { useContext, useState } from 'react'
import { Box, Button, Divider, FormControl, FormLabel, Grid } from '@material-ui/core'
import generateId from 'src/utils/libs/generateId'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { FieldArray, useField } from 'formik'
import { FieldSchema } from 'src/types/FieldSchema'
import { useTranslation } from 'react-i18next'
import DeleteIcon from '@material-ui/icons/Delete'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { Category } from 'src/types/Category'
import { FirebaseContext } from 'src/utils/firebase'
import SelectField from 'src/components/Molecules/FormFields/SelectField'
import ConfirmDialog from 'src/components/Molecules/ConfirmDialog'
import { RegionValueObject } from 'src/types/RegionValueObject'
import { CategorySelectField } from 'src/components/Molecules/FormFields/Deal'

type Props = {
  name: string
  schema: FieldSchema
}

export default function SubsidiaryArrayField({ name, ...rest }: Props) {
  const [field] = useField(name)
  const values = field.value ?? []
  const [t] = useTranslation()
  const classes = useStyles()
  const { firebase } = useContext(FirebaseContext)
  type ExpendedValues = { [key: number]: boolean }

  const [expendedValues, setExpendedValues] = useState<ExpendedValues>(values?.map(({}) => true))

  const [regions] = useCollectionData<RegionValueObject>(firebase.firestore().collection('regions'))

  const handleChange = (index: number) => {
    let isExpended: boolean = expendedValues[index] ? !expendedValues[index] : true
    setExpendedValues({ ...expendedValues, [index]: isExpended })
  }

  const handleExpandAll = () => {
    setExpendedValues(values?.map(({}) => true))
  }

  const handleCollapseAll = () => {
    setExpendedValues(values?.map(({}) => false))
  }

  type State = {
    selectedItem: Subsidiary | null
    selectedIndex: number
  }
  const [state, setState] = useState<State>({
    selectedItem: null,
    selectedIndex: 0
  })

  const handleRemoveSubsidiary = (subsidiary: Subsidiary | null, index: number) => {
    setState({ ...state, ['selectedItem']: subsidiary, ['selectedIndex']: index })
  }

  const [categories] = useCollectionData<Category>(firebase.firestore().collection('categories'), {
    idField: 'id'
  })

  return (
    <FormControl component="fieldset" className={classes.root} {...rest}>
      <Box display="flex" mb={1} justifyContent="flex-end">
        <Box>
          <Button variant={'outlined'} onClick={handleExpandAll} size={'small'}>
            {t('action.expandAll')}
            <ExpandMoreIcon />
          </Button>
        </Box>
        <Box ml={1}>
          <Button variant={'outlined'} onClick={handleCollapseAll} size={'small'}>
            {t('action.collapseAll')}
            <ExpandLessIcon />
          </Button>
        </Box>
      </Box>

      <FormLabel component="legend">
        {values.length > 1 ? t('merchant.subsidiaries') : t('merchant.subsidiary')}
      </FormLabel>
      <FieldArray
        name={name}
        render={(arrayHelpers) => {
          return (
            <>
              {values.map((value: Subsidiary, index: number) => {
                return (
                  <Accordion
                    key={index}
                    expanded={expendedValues[index] ?? true}
                    onChange={() => {
                      handleChange(index)
                    }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header">
                      <Typography className={classes.heading}>
                        {!expendedValues[index] && value.name}
                      </Typography>
                      <Typography className={classes.secondaryHeading}>
                        {!expendedValues[index] && value.locality}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                          <InputField
                            name={`${name}.${index}.name`}
                            schema={{
                              label: 'Nom'
                            }}
                            paddingBottom={2}
                            fullWidth
                          />

                          <InputField
                            name={`${name}.${index}.address`}
                            schema={{
                              label: 'Adresse'
                            }}
                            multiline
                            rows={2}
                            fullWidth
                          />

                          <Grid item container>
                            <Grid item xs={4}>
                              <InputField
                                name={`${name}.${index}.postalCode`}
                                schema={{
                                  label: 'NPA'
                                }}
                                paddingRight={2}
                                fullWidth
                              />
                              &nbsp;
                            </Grid>

                            <Grid item xs={8}>
                              <InputField
                                name={`${name}.${index}.locality`}
                                schema={{
                                  label: 'Localité'
                                }}
                                fullWidth
                              />
                            </Grid>
                          </Grid>

                          <SelectField
                            name={`${name}.${index}.region`}
                            label="Région"
                            data={Array.isArray(regions) ? regions : []}
                            fullWidth
                          />
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <InputField
                            name={`${name}.${index}.website`}
                            schema={{
                              label: 'Site'
                            }}
                            placeholder={'https://website.com'}
                            paddingBottom={2}
                            fullWidth
                          />

                          <Box mb={3.5}>
                            <CategorySelectField
                              categories={Array.isArray(categories) ? categories : []}
                              name={`${name}.${index}.category`}
                              label={'Catégorie'}
                            />
                          </Box>

                          <InputField
                            name={`${name}.${index}.description`}
                            schema={{
                              label: 'Description'
                            }}
                            multiline
                            rows={2}
                            fullWidth
                          />

                          <Typography
                            color="textSecondary"
                            variant="body2"
                            className={classes.section}>
                            Coordonnées de paiement
                          </Typography>
                          <Divider variant="fullWidth" className={classes.divider} />

                          <InputField
                            name={`${name}.${index}.paymentTo`}
                            schema={{
                              label: 'En faveur de'
                            }}
                            fullWidth
                          />

                          <Grid item container>
                            <Grid item xs={4}>
                              <InputField
                                name={`${name}.${index}.paymentPostalCode`}
                                schema={{
                                  label: 'NPA'
                                }}
                                paddingRight={2}
                                fullWidth
                              />
                              &nbsp;
                            </Grid>

                            <Grid item xs={8}>
                              <InputField
                                name={`${name}.${index}.paymentLocality`}
                                schema={{
                                  label: 'Localité'
                                }}
                                fullWidth
                              />
                            </Grid>
                          </Grid>

                          <InputField
                            name={`${name}.${index}.iban`}
                            schema={{
                              label: 'Numéro IBAN'
                            }}
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} className={classes.right}>
                          <Button
                            type="button"
                            variant="outlined"
                            onClick={() => {
                              handleRemoveSubsidiary(value, index)
                            }}>
                            <DeleteIcon />
                          </Button>

                          <ConfirmDialog
                            type="delete"
                            open={state.selectedItem !== null}
                            additionalText={state.selectedItem?.name}
                            onPerformAction={() => {
                              arrayHelpers?.remove(state.selectedIndex)

                              // Trick: we should clone and remove the element as arrayHelpers is asynchronouse
                              const _values = Array.from(values)
                              _values.pop()
                              setExpendedValues(_values?.map(() => true))
                            }}
                            onCloseAction={() => {
                              handleRemoveSubsidiary(null, 0)
                            }}
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                )
              })}

              <Grid container spacing={3}>
                <Grid item>
                  <Button
                    className={classes.btnAdd}
                    type="button"
                    variant="outlined"
                    onClick={() => {
                      handleChange(values.length - 1 + 1)
                      arrayHelpers.push({
                        id: generateId(),
                        name: '',
                        locality: '',
                        address: '',
                        postalCode: '',
                        website: '',
                        region: ''
                      })
                    }}>
                    {values.length ? (
                      <>
                        <AddIcon /> {t('action.addSubsidiaries')}
                      </>
                    ) : (
                      <>
                        <EditIcon /> {t('action.merchantDetails')}
                      </>
                    )}
                  </Button>
                </Grid>
              </Grid>
            </>
          )
        }}
      />
    </FormControl>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    section: {
      marginTop: theme.spacing(2)
    },
    divider: {
      marginBottom: theme.spacing(1)
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary
    },
    btnAdd: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2)
    },
    right: {
      textAlign: 'right'
    }
  })
)
