import React, { useContext } from 'react'
import { FirebaseContext } from 'src/utils/firebase'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { Merchant } from 'src/types/Merchant'
import MerchantForm from 'src/components/Views/Merchant/Form'
import { merchantInitialValues } from 'src/components/Views/Merchant/Form'
import ErrorBoundary from 'src/components/Molecules/ErrorBoundary'
import { Subsidiary } from '@yodo/types/dist/Merchant'

export default function EditForm() {
  const { firebase, token } = useContext(FirebaseContext)
  const merchantId = token?.claims?.merchantId ?? null

  const [merchant, loading, error] = useDocumentData<Merchant>(
    firebase.firestore().collection('merchants').doc(merchantId),
    {
      idField: 'id'
    }
  )

  if (error) {
    return <ErrorBoundary error={error} />
  }

  // Check that we have a region value since the field is mandatory
  merchant?.subsidiaries?.map((subsidiary: Subsidiary) => {
    if (!subsidiary?.region) {
      subsidiary.region = ''
    }
  })
  return (
    <>
      {!loading && (
        <MerchantForm initialValues={merchant ?? merchantInitialValues} id={merchantId} />
      )}
    </>
  )
}
