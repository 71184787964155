import React from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  error: any
}
export default function ErrorBoundary({ error }: Props) {
  // todo we should log the crash with a proper logger!
  const [t] = useTranslation()
  return (
    <div>
      <h2>{t('error.general')}</h2>
      <details style={{ whiteSpace: 'pre-wrap' }}>
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </details>
    </div>
  )
}
